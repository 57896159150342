import React, { useState, useEffect } from 'react';
import {
  Paper,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { API_BASE } from '../helper/constants';

// import { Container } from './styles';

function ResetPassword({ history, match }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof match.params.token === 'undefined') history.push('/login');
  }, []);

  const fetchToken = async () => {
    try {
      setLoading(true);
      const { token } = match.params;
      const request = await axios.post(`${API_BASE}/auth/reset_password`, {
        email,
        password,
        token,
      });
      //console.log(request);
      setSuccess(true);
      setLoading(false);
      //console.log(request.data);
    } catch (err) {
      setLoading(false);
      //console.log(err);
    }
  };

  return (
    <div className="forgot-password-container">
      <Paper className="paper-wrapper">
        {success ? (
          <div>
            <Typography>
              You have successfully reset your password, just login with your
              new password :)
            </Typography>
            <Button
              color="primary"
              style={{ marginTop: 20 }}
              variant="contained"
              margin="normal"
              fullWidth
              onClick={() => history.push('/login')}
            >
              Login
            </Button>
          </div>
        ) : (
          <div>
            <Typography>
              What's the new password you want? And let us confirm who you are
              :)
            </Typography>
            <TextField
              value={email}
              id="standard-basic"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              placeholder="e.g. myemail@domain.com"
              type="email"
              fullWidth
              style={{ marginRight: 10 }}
            />
            <TextField
              value={password}
              id="standard-basic"
              label="New Password"
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              placeholder="e.g. bread123"
              type="password"
              fullWidth
              style={{ marginRight: 10 }}
            />
            {loading ? (
              <CircularProgress size={26} />
            ) : (
              <Button
                color="primary"
                style={{ marginTop: 20 }}
                variant="contained"
                margin="normal"
                fullWidth
                onClick={() => fetchToken()}
                disabled={loading || email.length < 3}
              >
                Confirm
              </Button>
            )}
          </div>
        )}
      </Paper>
    </div>
  );
}

export default ResetPassword;
