import {
  CardActionArea,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { withStyles } from '@material-ui/styles';
import axios from 'axios';
import Card from 'material-ui/Card';
import DatePicker from 'material-ui/DatePicker';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import RaisedButton from 'material-ui/RaisedButton';
import SnackBar from 'material-ui/Snackbar';
import { Step, StepContent, StepLabel, Stepper } from 'material-ui/Stepper';
import TextField from 'material-ui/TextField';
import moment from 'moment';
import React, { Component } from 'react';
import {
  returnInicialLetters,
  returnScheduleDisabledV2,
  slotTimeToString,
  timeArrayByStartFinish,
} from './buildTimeStringsArray';
import { addItem } from './helper/Cart';
import { API_BASE } from './helper/constants';
import Header from './helper/Header';
import { UserProvider, UserStateContext } from './helper/UserContext';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 5,
    marginRight: 5,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class AppointmentApp extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      schedule: [],
      confirmationModalOpen: false,
      appointmentDateSelected: false,
      appointmentMeridiem: 0,
      validEmail: true,
      validPhone: true,
      finished: false,
      smallScreen: window.innerWidth < 768,
      stepIndex: 0,
      service: '',
      providers: [],
      providerSelected: 0,
      isLoading: true,
      selectedDate: false,
      noTimeAvailable: false,
      checkedSave: true,
      slots: [],
      appointmentSlot: [],
      appointmentDate: '',
      appointmentId: '',
      isReeschedule: false,
      disabledDates: [],
      error: '',
    };
  }
  async componentWillMount() {
    // axios.get(API_BASE + `/appointment/slots/day`).then((response) => {
    //   this.setState({ slots: response.data.slots });
    // });

    //const providers = await axios.get(API_BASE + '/providers');
    // const serviceId = '5ef8cea630eed2098fdf5742';
    const serviceId = this.props.location.service;
    if (!serviceId) this.props.history.push('/');
    //console.log(this.props.location.service);
    const providers = await axios.get(
      API_BASE + '/provider/listForService/' + `${serviceId}`
    );
    console.log(providers.data);
    var service;
    try {
      service = await axios.get(API_BASE + '/service/' + `${serviceId}`);
    } catch (err) {
      console.log(err);
    }
    console.log(service.data);
    const { isReeschedule, appointment } = this.props.location;
    if (isReeschedule && appointment) {
      this.setState({
        isReeschedule: isReeschedule,
        appointmentId: appointment._id,
      });
      this.populateFromAppointment();
    }
    this.setState({ providers: providers.data, service: service.data }, () => {
      this.setState({ isLoading: false });
    });
  }

  populateFromAppointment = () => {
    console.log('Hey I would like to populate the component with some info');
  };

  fetchDisabledDates = async (provider) => {
    try {
      const days = await axios.get(
        `${API_BASE}/appointment/list/disabledDates/${provider._id}`
      );

      this.setState({ disabledDates: days.data.dates });

      // setLoading(false);
    } catch (err) {
      console.log(err);
      // setLoading(false);
    }
  };

  handleNext = () => {
    const { stepIndex } = this.state;
    if (stepIndex == 2) {
      if (typeof window !== 'undefined' && localStorage.getItem('contact')) {
        const { firstName, lastName, email, phone } = JSON.parse(
          localStorage.getItem('contact')
        );
        this.setState({
          firstName,
          lastName,
          email:
            (this.context && this.context.user && this.context.user.email) ||
            email,
          phone,
        });
      }
    }
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 3,
    });
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  validateEmail(email) {
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    this.setState({ email: email, validEmail: true });
    return regex.test(email)
      ? this.setState({ validEmail: true })
      : this.setState({ validEmail: false });
  }
  validatePhone(phoneNumber) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/;
    this.setState({ phone: phoneNumber, validPhone: true });
    return regex.test(phoneNumber)
      ? this.setState({ validPhone: true })
      : this.setState({ validPhone: false });
  }

  handleAddToCart(appointment) {
    console.log('Adding to cart');
    const { providerSelected, providers, service } = this.state;
    // const newAppointment = {
    //   name: this.state.firstName + ' ' + this.state.lastName,
    //   email: this.state.email,
    //   phone: this.state.phone,
    //   slot_date: moment(this.state.appointmentDate).format('YYYY-MM-DD'),
    //   slot_time: this.state.appointmentSlot,
    //   provider: providers[providerSelected],
    //   service: service,
    // };
    this.props.startCount();
    addItem(appointment, () => console.log('Added to cart'));
  }

  renderStepActions(step) {
    const { stepIndex } = this.state;

    return (
      <div style={{ margin: '12px 0' }}>
        {step > 0 && (
          <FlatButton
            label="Back"
            disabled={stepIndex === 0}
            disableTouchRipple={true}
            disableFocusRipple={true}
            onClick={this.handlePrev}
          />
        )}
        {step != 3 && (
          <RaisedButton
            label={stepIndex === 3 ? 'Finish' : 'Next'}
            disableTouchRipple={true}
            disableFocusRipple={true}
            primary={true}
            onClick={this.handleNext}
            disabled={!this.nextEnabled(step)}
            backgroundColor="#00C853 !important"
            style={{ marginRight: 12, backgroundColor: '#00C853' }}
          />
        )}
        {/* {step == 3 && (
          <Button
            style={{ borderColor: '#51bad1', color: '#51bad1' }}
            variant="outlined"
            disableTouchRipple={true}
            disableFocusRipple={true}
            onClick={() => this.handleAddToCart()}
          >
            Add to cart
          </Button>
        )} */}
      </div>
    );
  }
  nextEnabled = (step) => {
    switch (step) {
      case 0:
        return true;
      case 1:
        return this.state.selectedDate;
      case 2:
        return this.state.appointmentSlot.length > 0;
      default:
        return true;
    }
  };

  returnSlotArray(start, duration) {
    const [h, m] = start.split(':');
    const startSlot = h * 4 + m / 15;
    const endSlot = startSlot + duration;
    return [startSlot, endSlot];
  }

  handleSetAppointmentDate(date) {
    if (this.state.appointmentDate != date)
      this.fetchSlotsForDate(moment(date).format('YYYY-MM-DD'));
    this.setState({
      appointmentDate: date,
      confirmationTextVisible: true,
      selectedDate: true,
      noTimeAvailable: false,
    });
  }

  async fetchSlotsForDate(date) {
    const slots = await axios.get(API_BASE + `/appointment/slots/day`, {
      params: { date },
    });

    console.log(slots.data);
    this.setState({ slots: slots.data.slots });
  }

  handleSetAppointmentSlot(slot) {
    console.log(slot);
    const { service } = this.state;
    const slotArray = this.returnSlotArray(slot, service.timeInterval);
    console.log(slotArray);
    this.setState({ appointmentSlot: slotArray });
  }

  handleSetAppointmentMeridiem(meridiem) {
    this.setState({ appointmentMeridiem: meridiem });
  }

  checkDisableDate(day) {
    const dateString = moment(day).format('YYYY-DD-MM');
    const { providerSelected, providers } = this.state;
    const dayOfTheWeek = moment(day).isoWeekday() - 1;
    const workHours = providers[providerSelected].days[dayOfTheWeek].slots;
    if (!workHours.length) return true;
    return (
      this.state.schedule[dateString] === true ||
      moment(day).startOf('day').diff(moment().startOf('day')) < 0 ||
      this.state.disabledDates.some(
        (item) => moment(day).format('YYYY-MM-DD') == item
      )
    );
  }

  renderAppointmentTimes(_enum) {
    if (this.state.selectedDate) {
      var displayHours = [];
      const { providerSelected, slots, providers, service } = this.state;
      const dayOfTheWeek = moment(this.state.appointmentDate).isoWeekday() - 1;
      const workHours = providers[providerSelected].days[dayOfTheWeek].slots;

      for (var i = 0; i < workHours.length; ++i) {
        let times = [];
        switch (_enum) {
          case 'Morning':
            times = timeArrayByStartFinish(
              workHours[i][0],
              workHours[i][1] - service.timeInterval, // - "service duration",
              0,
              47
            );
            break;
          case 'Afternoon':
            times = timeArrayByStartFinish(
              workHours[i][0],
              workHours[i][1] - service.timeInterval, // - "service duration",
              48,
              71
            );
            break;
          case 'Evening':
            times = timeArrayByStartFinish(
              workHours[i][0],
              workHours[i][1] - service.timeInterval, // - "service duration",
              72,
              96
            );
            break;
          default:
            times = timeArrayByStartFinish(
              workHours[i][0],
              workHours[i][1] - service.timeInterval // - "service duration"
            );
        }

        for (var j = 0; j < times.length; ++j) {
          displayHours.push(times[j]);
        }
      }

      // if (displayHours.length == 0 && !this.state.noTimeAvailable)
      //   this.setState({ noTimeAvailable: true });

      return displayHours.map((time) => {
        const time1 = moment(time, 'HH:mm');
        const time2 = moment(time, 'HH:mm').add(
          15 * service.timeInterval,
          'minutes'
        );
        const date = moment(this.state.appointmentDate);

        var scheduleDisabled = returnScheduleDisabledV2(
          time1,
          time2,
          slots,
          providers,
          providerSelected,
          date
        );

        if (displayHours.length >= 1) {
          return (
            <RadioButton
              label={time1.format('HH:mm') + ' - ' + time2.format('HH:mm')}
              key={time1}
              value={time}
              style={{
                marginBottom: 5,
                display: false ? 'none' : 'inherit',
              }}
              disabled={scheduleDisabled}
            />
          );
        }
      });
    } else {
      return null;
    }
  }

  renderAppointmentConfirmation() {
    const spanStyle = { color: '#00C853' };
    return (
      <section>
        <Typography variant="h5" color="error">
          {this.state.error}
        </Typography>
        <p>
          Name:{' '}
          <span style={spanStyle}>
            {this.state.firstName} {this.state.lastName}
          </span>
        </p>
        <p>
          Number: <span style={spanStyle}>{this.state.phone}</span>
        </p>
        <p>
          Email: <span style={spanStyle}>{this.state.email}</span>
        </p>
        <p>
          Appointment:{' '}
          <span style={spanStyle}>
            {moment(this.state.appointmentDate).format(
              'dddd[,] MMMM Do[,] YYYY'
            )}
          </span>{' '}
          at{' '}
          <span style={spanStyle}>
            {moment()
              .hour(0)
              .minute(0)
              .add(this.state.appointmentSlot[0] * 15, 'minutes')
              .format('HH:mm')}
          </span>
        </p>
        <p>
          Service: <span style={spanStyle}>{this.state.service.name}</span>
        </p>
      </section>
    );
  }

  handleReeschedule = async () => {
    if (!this.state.appointmentId) return;
    try {
      const reescheduledAppointment = {
        slots: this.state.appointmentSlot,
        date: moment(this.state.appointmentDate).format('YYYY-MM-DD'),
      };
      const request = await axios.put(
        `${API_BASE}/appointment/reeschedule/${this.state.appointmentId}`,
        reescheduledAppointment
      );
      console.log(request.data);
    } catch (err) {
      console.log(err);
      this.setState({ error: err.response.error });
    }
  };

  async handleSubmit(next) {
    this.setState({ error: '' });
    if (this.state.isReeschedule) {
      await this.handleReeschedule();
      next();
      return;
    }

    this.setState({ confirmationModalOpen: false });
    const { providerSelected, providers, service } = this.state;

    const { user } = this.context;

    const newAppointment = {
      name: this.state.firstName + ' ' + this.state.lastName,
      email: (user && user.email) || this.state.email,
      phone: this.state.phone,
      slot_date: moment(this.state.appointmentDate).format('YYYY-MM-DD'),
      slot_time: this.state.appointmentSlot,
      provider: providers[providerSelected]._id,
      service: service._id,
      // slot_time: this.state.appointmentSlot,
    };
    //console.log(newAppointment);
    var self = this;
    axios
      .post(API_BASE + '/appointment/create', newAppointment)
      .then((response) => {
        this.handleAddToCart(response.data);
        console.log(response.data);
        this.setState(
          {
            confirmationSnackbarMessage:
              "Appointment succesfully added! You'll be redirect in 3 seconds",
            confirmationSnackbarOpen: true,
            processed: true,
          },
          () => {
            next();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        return this.setState({
          confirmationSnackbarMessage:
            'Appointment failed to save. Please try at another time slot.',
          confirmationSnackbarOpen: true,
        });
      });
  }

  redirectToCart() {
    setTimeout(() => {
      this.props.history.push('/cart');
    }, 3000);
  }

  redirectToServices() {
    setTimeout(() => {
      this.props.history.push('/services');
    }, 3000);
  }

  handleSelectProvider(index) {
    this.fetchDisabledDates(this.state.providers[index]);

    this.handleNext();
    this.setState({ providerSelected: index });
  }

  providerPicker() {
    const { providers } = this.state;
    const { classes } = this.props;
    return (
      <GridList cellHeight={85} className={classes.gridList} cols={1}>
        {providers.map((tile, index) => (
          <GridListTile
            key={tile.name}
            cols={tile.cols || 1}
            className={classes.root}
          >
            <Card className={classes.root}>
              <CardActionArea className={classes.flex}>
                <CardContent onClick={() => this.handleSelectProvider(index)}>
                  <div className={classes.cardContent}>
                    <Avatar>{returnInicialLetters(tile.name)}</Avatar>
                    <Typography
                      variant="h6"
                      component="h4"
                      className={classes.marginLeft}
                    >
                      {tile.name}
                    </Typography>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
            <img src={tile.img} alt={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    );
  }

  render() {
    const {
      finished,
      isLoading,
      smallScreen,
      stepIndex,
      confirmationModalOpen,
      confirmationSnackbarOpen,
      checkedSave,
      providers,
      providerSelected,
      ...data
    } = this.state;
    const contactFormFilled =
      data.firstName &&
      data.lastName &&
      data.phone &&
      data.email &&
      data.validPhone &&
      data.validEmail;
    const DatePickerExampleSimple = () => (
      <div>
        <DatePicker
          value={this.state.appointmentDate}
          hintText="Select Date"
          mode={smallScreen ? 'portrait' : 'landscape'}
          onChange={(n, date) => this.handleSetAppointmentDate(date)}
          shouldDisableDate={(day) => this.checkDisableDate(day)}
          maxDate={new Date(moment().add(60, 'days'))}
        />
      </div>
    );
    const modalActions = [
      <FlatButton
        label="Cancel"
        primary={false}
        onClick={() => this.setState({ confirmationModalOpen: false })}
      />,
      <Button
        style={{ borderColor: '#51bad1', color: '#51bad1' }}
        variant="outlined"
        disableTouchRipple={true}
        disableFocusRipple={true}
        onClick={() => this.handleSubmit(() => this.redirectToCart())}
      >
        Add to cart
      </Button>,
      <Button
        style={{ borderColor: '#51bad1', color: '#51bad1', marginLeft: 10 }}
        variant="outlined"
        disableTouchRipple={true}
        disableFocusRipple={true}
        onClick={() => this.handleSubmit(() => this.redirectToServices())}
      >
        Add to cart and schedule more
      </Button>,
      // <FlatButton
      //   label="Add to cart and continue scheduling"
      //   style={{ backgroundColor: '#00C853 !important' }}
      //   primary={true}
      //   onClick={() => this.handleSubmit(() => this.redirectToServices())}
      // />,
    ];
    return (
      <div>
        <Header
          {...this.props}
          cart
          title="Appointment Scheduler"
          onCartClick={() => console.log('Push cart side menu')}
        />
        <section
          style={{
            maxWidth: !smallScreen ? '80%' : '100%',
            margin: 'auto',
            marginTop: !smallScreen ? 20 : 0,
          }}
        >
          <Card
            style={{
              padding: '12px 12px 25px 12px',
              height: smallScreen ? '100vh' : null,
            }}
          >
            <Stepper
              activeStep={stepIndex}
              orientation="vertical"
              linear={false}
            >
              <Step>
                <StepLabel>
                  Choose the provider for you appointment:{' '}
                  {!isLoading &&
                    providers.length > 0 &&
                    providers[providerSelected].name}
                </StepLabel>
                <StepContent>
                  {this.providerPicker()}
                  {this.renderStepActions(0)}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  Choose an available day for your appointment
                </StepLabel>
                <StepContent>
                  {DatePickerExampleSimple()}
                  {this.renderStepActions(1)}
                </StepContent>
              </Step>
              <Step disabled={!data.appointmentDate}>
                <StepLabel>
                  Choose an available time for your appointment
                </StepLabel>
                <StepContent>
                  {this.state.noTimeAvailable ? (
                    <Typography>No time available at that date</Typography>
                  ) : (
                    <></>
                  )}
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography>Morning</Typography>
                      <Divider />
                      <RadioButtonGroup
                        style={{
                          marginTop: 15,
                          marginLeft: 15,
                        }}
                        name="appointmentTimes"
                        onChange={(evt, val) =>
                          this.handleSetAppointmentSlot(val)
                        }
                        valueSelected={
                          data.appointmentSlot.length &&
                          slotTimeToString(data.appointmentSlot[0])
                        }
                      >
                        {this.renderAppointmentTimes('Morning')}
                      </RadioButtonGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography>Afternoon</Typography>
                      <Divider />
                      <RadioButtonGroup
                        style={{
                          marginTop: 15,
                          marginLeft: 15,
                        }}
                        name="appointmentTimes"
                        onChange={(evt, val) =>
                          this.handleSetAppointmentSlot(val)
                        }
                        valueSelected={
                          data.appointmentSlot.length &&
                          slotTimeToString(data.appointmentSlot[0])
                        }
                      >
                        {this.renderAppointmentTimes('Afternoon')}
                      </RadioButtonGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography>Evening</Typography>
                      <Divider />
                      <RadioButtonGroup
                        style={{
                          marginTop: 15,
                          marginLeft: 15,
                        }}
                        name="appointmentTimes"
                        onChange={(evt, val) =>
                          this.handleSetAppointmentSlot(val)
                        }
                        valueSelected={
                          data.appointmentSlot.length &&
                          slotTimeToString(data.appointmentSlot[0])
                        }
                      >
                        {this.renderAppointmentTimes('Evening')}
                      </RadioButtonGroup>
                    </Grid>
                  </Grid>
                  {this.renderStepActions(2)}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  Share your contact information with us and we'll send you a
                  reminder
                </StepLabel>
                <StepContent>
                  <section>
                    <TextField
                      style={{ display: 'block' }}
                      name="first_name"
                      value={data.firstName}
                      hintText="First Name"
                      floatingLabelText="First Name"
                      onChange={(evt, newValue) =>
                        this.setState({ firstName: newValue })
                      }
                    />
                    <TextField
                      style={{ display: 'block' }}
                      name="last_name"
                      value={data.lastName}
                      hintText="Last Name"
                      floatingLabelText="Last Name"
                      onChange={(evt, newValue) =>
                        this.setState({ lastName: newValue })
                      }
                    />
                    <TextField
                      style={{ display: 'block' }}
                      name="email"
                      value={data.email}
                      hintText="youraddress@mail.com"
                      floatingLabelText="Email"
                      errorText={
                        data.validEmail ? null : 'Enter a valid email address'
                      }
                      onChange={(evt, newValue) => this.validateEmail(newValue)}
                    />
                    <TextField
                      style={{ display: 'block' }}
                      name="phone"
                      value={data.phone}
                      hintText="+2348995989"
                      floatingLabelText="Phone"
                      errorText={
                        data.validPhone ? null : 'Enter a valid phone number'
                      }
                      onChange={(evt, newValue) => this.validatePhone(newValue)}
                    />
                    <FormControl component="fieldset">
                      <FormGroup
                        aria-label="position"
                        row
                        style={{ marginLeft: 5 }}
                      >
                        <FormControlLabel
                          value="CheckedSave"
                          control={
                            <Switch
                              checked={checkedSave}
                              onChange={(event) =>
                                this.setState({
                                  checkedSave: event.target.checked,
                                })
                              }
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="I want to save my contact to future schedules"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                    <Button
                      variant="contained"
                      style={{ display: 'block', backgroundColor: '#00C853' }}
                      label={
                        contactFormFilled
                          ? 'Schedule'
                          : 'Fill out your information to schedule'
                      }
                      //labelPosition="before"
                      primary="true"
                      fullWidth={true}
                      onClick={() => {
                        this.setState({
                          confirmationModalOpen:
                            !this.state.confirmationModalOpen,
                        });
                        if (typeof window === 'undefined') return;
                        if (checkedSave === false) {
                          if (localStorage.getItem('contact'))
                            localStorage.removeItem('contact');
                          return;
                        }
                        const jsonData = JSON.stringify({
                          firstName: data.firstName,
                          lastName: data.lastName,
                          email: data.email,
                          phone: data.phone,
                        });
                        localStorage.setItem('contact', jsonData);
                      }}
                      disabled={!contactFormFilled || data.processed}
                      style={{ marginTop: 20 }}
                    >
                      {contactFormFilled
                        ? 'Schedule'
                        : 'Fill out your information to schedule'}
                    </Button>
                  </section>
                  {this.renderStepActions(3)}
                </StepContent>
              </Step>
            </Stepper>
          </Card>
          <Dialog
            modal={true}
            open={confirmationModalOpen}
            actions={modalActions}
            title="Confirm your appointment"
          >
            {this.renderAppointmentConfirmation()}
          </Dialog>
          <SnackBar
            open={confirmationSnackbarOpen || isLoading}
            message={
              isLoading ? 'Loading... ' : data.confirmationSnackbarMessage || ''
            }
            autoHideDuration={10000}
            onRequestClose={() =>
              this.setState({ confirmationSnackbarOpen: false })
            }
          />
        </section>
      </div>
    );
  }
}
AppointmentApp.contextType = UserStateContext;

export default withStyles(styles)(AppointmentApp);
