import React, { useState } from 'react';
import {
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core';
import axios from 'axios';
import { API_BASE } from '../helper/constants';

// import { Container } from './styles';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchToken = async () => {
    try {
      setLoading(true);
      const request = await axios.post(`${API_BASE}/auth/forgot_password`, {
        email,
      });
      setSuccess(true);
      setLoading(false);
      //console.log(request);
    } catch (err) {
      setLoading(false);
      //console.log(err);
    }
  };

  return (
    <div className="forgot-password-container">
      <Paper className="paper-wrapper">
        {success ? (
          <Typography>
            An confirmation email has been sent to the email provided, follow
            the instructions there to complete your reset :) {'\n'}Be sure to
            check your spam if you don't find it on your mailbox
          </Typography>
        ) : (
          <div>
            <Typography>
              With which registered email did you forget the password?
            </Typography>
            <TextField
              value={email}
              id="standard-basic"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              placeholder="e.g. myemail@somedomain.com"
              type="email"
              fullWidth
              style={{ marginRight: 10 }}
            />
            {loading ? (
              <CircularProgress size={26} />
            ) : (
              <Button
                color="primary"
                style={{ marginTop: 20 }}
                variant="contained"
                margin="normal"
                fullWidth
                onClick={() => fetchToken()}
                disabled={loading || email.length < 3}
              >
                Confirm
              </Button>
            )}
          </div>
        )}
      </Paper>
    </div>
  );
}

export default ForgotPassword;
