import { Button, Divider, Grid, Select, Typography } from '@material-ui/core';
import axios from 'axios';
import { MenuItem } from 'material-ui';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { API_BASE } from '../../components/helper/constants';
import labels from '../../services/translation';
import { CustomSelectField, CustomTextField } from './components/CustomFields';

const ConsultationForm = () => {
  const [lang, setLang] = useState('en');
  // const [data, setData] = useState(formInitialState);

  // HOOKS
  const params = useParams();
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  // METHODS
  const submitForm = async (data) => {
    const { formId } = params;

    const response = await axios.post(
      API_BASE + '/appointments/consultationForm',
      { form: data },
      { params: { formId } }
    );
  };

  return (
    <div
      style={{
        padding: 20,
        maxWidth: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <img
        src={process.env.REACT_APP_LOGO_URL}
        style={{
          height: 200,
          width: 300,
          objectFit: 'contain',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />

      <div>
        {/* FORM */}
        <Typography variant="h6" gutterBottom>
          {labels.LONG_USER_INTERACTIVE_FORM.TITLE[lang]}
        </Typography>

        <form onSubmit={handleSubmit((data) => submitForm(data))}>
          <Grid container spacing={3}>
            {/* THE FIRST THING SHOULD BE A SELECTOR ASKING THE LANGUAGE THE PERSON WANTS THE FORM IN (VERY IMPORTANT) */}
            <Grid item xs={12}>
              <Select
                value={lang}
                onChange={(e) => setLang(e.target.value)}
                fullWidth
                label="Your main language"
              >
                {[
                  { value: 'pt', name: 'Português' },
                  { value: 'en', name: 'English' },
                ].map((item) => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <CustomSelectField
              size={{ sm: 12 }}
              register={register}
              registerOptions={{ required: true }}
              name="typeOfTreatment"
              label={labels.LONG_USER_INTERACTIVE_FORM.TYPE_OF_TREATMENT[lang]}
              options={labels.LONG_USER_INTERACTIVE_FORM.TYPE_OF_TREATMENT.options.map(
                (item) => ({
                  value: item[lang],
                  name: item[lang],
                })
              )}
            />

            <CustomTextField
              name="firstName"
              label={labels.LONG_USER_INTERACTIVE_FORM.FIRST_NAME[lang]}
              register={register}
              registerOptions={{ required: true }}
            />
            <CustomTextField
              register={register}
              registerOptions={{ required: true }}
              name="lastName"
              label={labels.LONG_USER_INTERACTIVE_FORM.SURNAME[lang]}
            />
            <CustomTextField
              register={register}
              registerOptions={{ required: true }}
              name="address"
              label={labels.LONG_USER_INTERACTIVE_FORM.ADDRESS[lang]}
            />
            <CustomTextField
              register={register}
              registerOptions={{ required: true }}
              name="occupation"
              label={labels.LONG_USER_INTERACTIVE_FORM.OCCUPATION[lang]}
            />
            <CustomSelectField
              name="typeOfTreatment"
              registerOptions={{ required: true }}
              register={register}
              label={labels.LONG_USER_INTERACTIVE_FORM.TYPE_OF_TREATMENT[lang]}
              options={labels.LONG_USER_INTERACTIVE_FORM.TYPE_OF_TREATMENT.options.map(
                (item) => ({
                  value: item[lang],
                  name: item[lang],
                })
              )}
            />
          </Grid>
          {false && (
            <>
              <br />
              <Divider />

              <Divider />
            </>
          )}
        </form>
      </div>

      <div>
        {/* ACTIONS AND CONFIRMATION BUTTONS */}

        <Button variant="outlined" onClick={() => history.push('/cart')}>
          Voltar ao carrinho
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Próximo
        </Button>
      </div>
    </div>
  );
};

const formInitialState = {};

export default ConsultationForm;

const AddressForm = ({
  firstName = '',
  lastName = '',
  address = '',
  complement = '',
  cep = '',
  city = '',
  setDeliveryInformation,
  deliveryOptions,
  fetchDeliveryDays,
  next,
}) => {
  const methods = useForm();
  const history = useHistory();

  const submitForm = (data) => {
    setDeliveryInformation(data);
    next();
  };

  useEffect(() => {
    if (!deliveryOptions.length) fetchDeliveryDays();
  }, []);

  const cityOption = methods.watch('city') || city;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Endereço para entrega
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => submitForm(data))}>
          <Grid container spacing={3}>
            <CustomTextField
              required
              name="firstName"
              label="Nome"
              defaultValue={firstName}
            />
            <CustomTextField
              required
              name="lastName"
              label="Sobrenome"
              defaultValue={lastName}
            />
            <CustomTextField
              required
              name="address"
              label="Endereço"
              defaultValue={address}
            />
            <CustomTextField
              name="complement"
              label="Complemento"
              defaultValue={complement}
            />
            <CustomTextField
              required
              name="cep"
              label="CEP"
              defaultValue={cep}
            />
            <CustomSelectField
              required
              name="city"
              label="Cidade"
              options={(deliveryOptions || []).map((item) => ({
                value: item.city,
                name: item.city,
              }))}
              defaultValue={city}
            />
          </Grid>
          {/* {cityOption && (
            <>
              <br />
              <Divider />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingHorizontal: 20,
                  alignItems: 'center',
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <Typography variant="subtitle1" style={{ color: '#666' }}>
                  Frete
                </Typography>
                <div>
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#666', textAlign: 'right' }}
                  >
                    R${' '}
                    {deliveryOptions
                      .find((item) => item.city === cityOption)
                      .price.toFixed(2)}
                  </Typography>
                  <Typography variant="subtitle1">
                    Entrega até{' '}
                    {nextDeliveryDateFor(
                      deliveryOptions.find((item) => item.city === cityOption)
                    )}
                  </Typography>
                </div>
              </div>
              <Divider />
            </>
          )} */}
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={() => history.push('/cart')}>
              Voltar ao carrinho
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!cityOption}
            >
              Próximo
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
