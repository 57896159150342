import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';
import '../App.css';
// import AddressForm from './AddressForm';
// import PaymentForm from './PaymentForm';
import CartView from './CartView';
import logo from '../assets/logo.png';
import {
  returnCartContent,
  returnCartTotal,
  checkStillValid,
  eraseCart,
  getCostumerName,
} from './helper/Cart';

import axios from 'axios';
import DropIn from 'braintree-web-drop-in-react';
import Header from './helper/Header';

import Cards from 'react-credit-cards';
import 'react-credit-cards/lib/styles.scss';
import {
  TextField,
  InputLabel,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { API_BASE } from './helper/constants';
import { useUserState } from './helper/UserContext';

const inPlacePaymentEnabled =
  process.env.REACT_APP_IN_PLACE_PAYMENT === 'true' || false;

function Copyright() {
  return process.env.REACT_APP_SHOW_COPYRIGHT ||
    typeof process.env.REACT_APP_SHOW_COPYRIGHT === 'undefined' ? (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link
        color="inherit"
        href={
          process.env.REACT_APP_COPYRIGHT_LINK
            ? process.env.REACT_APP_COPYRIGHT_LINK
            : 'https://marketinglovers.co.uk/'
        }
      >
        {process.env.REACT_APP_COMPANY_NAME
          ? process.env.REACT_APP_COMPANY_NAME
          : 'Developed by Marketing Lovers'}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  ) : (
    <></>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#ffffff77',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  anotherServiceButton: {
    backgroundColor: '#F429E0',
    color: 'white',
    '&:hover': {
      background: '#F735E9',
    },
  },
}));

//const steps = ['Shipping address', 'Payment details', 'Review your order'];
const steps = ['Review your order', 'Payment Info'];
const paymentMethods = [
  { name: 'Credit Card', value: 'CREDIT_CARD' },
  { name: 'Money', value: 'CASH' },
];

export default function Checkout(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <CartView />;
      // return <AddressForm />;
      case 1:
        return <SumupPaymentView next={handleNext} />; //<PaymentView {...props} handleNext={() => handleNext()} />;
      // return <PaymentForm />;
      case 2:
      //return <CartView />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <div //id="gradient-background"
      >
        <CssBaseline />
        {/* <AppBar position="absolute" color="default" className={classes.appBar}>
          <Toolbar>
            <img src={logo} alt="Logo" id="logo-image" />
            <Typography variant="h6" color="inherit" noWrap>
              Amanda Wellness Spa
            </Typography>
          </Toolbar>
        </AppBar> */}
        <Header {...props} title="Book Now" />

        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <span>{props.timeRemaining}</span>
            <Typography component="h1" variant="h4" align="center">
              Checkout
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order has been completed successfully. We have emailed
                    your order confirmation, if you have any questions feel free
                    to contact us at {process.env.REACT_APP_CONTACT_EMAIL}
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {/* {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )} */}
                    {activeStep === 0 && (
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() =>
                          props.history.push({ pathname: '/services' })
                        }
                        className={[
                          classes.anotherServiceButton,
                          classes.button,
                        ]}
                      >
                        Schedule another service
                      </Button>
                    )}
                    {activeStep < steps.length - 1 &&
                    (activeStep === 1 || returnCartContent().length > 0) ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1
                          ? 'Place order'
                          : 'Next'}
                      </Button>
                    ) : null}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
          <Copyright />
        </main>
      </div>
    </React.Fragment>
  );
}

const SumupPaymentView = ({ next }) => {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const [error, setError] = useState(false);
  const [successfull, setSuccessfull] = useState(false);
  const [focus, setFocus] = useState('number');
  const [paymentMethod, setPaymentMethod] = React.useState('CREDIT_CARD');

  const { user } = useUserState();

  const _onChange = (form) => {
    const { valid, values } = form;
    setCardData({ valid, values });
  };

  useEffect(() => {
    // console.log(cardData);
  }, [cardData]);

  const handlePayment = async () => {
    //if (!cardData.valid || cardData.values.name === '') return;
    // console.log('ey');
    setLoading(true);
    try {
      setError('');
      const { number, expiry, cvc, name } = cardData;
      const [expiry_month, expiry_year] = expiry.split('/');
      const amount = 24.99;
      const description = `${await name.split(' ')[0]}, ${new Date()}`;
      const card = {
        name,
        number: number.replace(/[^0-9a-z]/gi, ''),
        expiry_month,
        expiry_year,
        cvc,
      };
      const response = await axios.post(`${API_BASE}/stripe/pay`, {
        card,
        amount,
        description,
        email: user.email,
        ids: sendIdsToUpdate(returnCartContent()),
        cart: returnCartContent(),
        coupom: localStorage.getItem('coupom')
          ? localStorage.getItem('coupom')
          : '',
        paymentMethod,
      });
      // console.log(response.data);
      if (response.data.paymentIntent.status === 'succeeded') {
        handleFinish();
        eraseCart();
        next();
      } else {
        setError(
          'There was problem with your payment, please revise it and try again'
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.response?.data?.error);
      // console.log(err.response?.data?.error);
    }
    // //if (!cardData.valid || cardData.values.name === '') return;
    // console.log('ey');
    // setLoading(true);
    // try {
    //   const { number, expiry, cvc, name } = cardData;
    //   const [expiry_month, expiry_year] = expiry.split('/');
    //   const amount = await returnCartTotal();
    //   const description = `${await getCostumerName()}, ${new Date()}`;
    //   const response = await finishTransaction(amount, description);
    //   console.log(response);
    //   const card = {
    //     name,
    //     number: number.replace(/[^0-9a-z]/gi, ''),
    //     expiry_month,
    //     expiry_year,
    //     cvc,
    //   };
    //   if (response.data.id) {
    //     const completedCheckout = await completeCheckout(
    //       response.data.id,
    //       card,
    //       next
    //     );
    //     if (completedCheckout.data.status === 'PAID') {
    //       handleFinish();
    //     }
    //   }

    //   setLoading(false);
    // } catch (err) {
    //   setLoading(false);
    //   console.log(err);
    // }
  };

  const handleFinish = () => {
    setSuccessfull(true);
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  useEffect(() => {
    // console.log(cardData);
  }, [cardData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  const classes = useStylesSumup();

  const handleChange = (event) => {
    setCardData({
      ...cardData,
      [event.target.name]: event.target.value,
    });
  };

  // console.log(
  //   'in place: ',
  //   inPlacePaymentEnabled,
  //   'payment method: ',
  //   paymentMethod
  // );

  return (
    <div>
      {!!error && (
        <Typography style={{ textAlign: 'center', color: '#f00' }}>
          {error}
        </Typography>
      )}

      {inPlacePaymentEnabled && (
        <>
          <Typography variant="h6" gutterBottom style={{ margin: '10px 0' }}>
            Choose the payment method
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            {paymentMethods.map((item) => (
              <Button
                style={{ flex: 1 }}
                variant={
                  paymentMethod === item.value ? 'contained' : 'outlined'
                }
                value={item.value}
                onClick={() => setPaymentMethod(item.value)}
                color="primary"
              >
                {item.name}
              </Button>
            ))}
            {/* <Typography variant="h6" gutterBottom style={{ margin: '20px 0' }}>
            Payment Method
          </Typography>
          <InputLabel>Choose the method</InputLabel>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required={true}
            fullWidth
            style={{ marginBottom: 20 }}
          >
            {paymentMethods.map((item) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            ))}
          </Select> */}
          </div>
        </>
      )}

      {inPlacePaymentEnabled && paymentMethod === 'CASH' && (
        <Typography>Your payment will be made in place.</Typography>
      )}
      {(!inPlacePaymentEnabled ||
        (inPlacePaymentEnabled && paymentMethod === 'CREDIT_CARD')) && (
        <div className="card-grid">
          <Cards
            cvc={cardData.cvc}
            expiry={cardData.expiry}
            focused={cardData.focus}
            name={cardData.name}
            number={cardData.number}
          />
          <form className={classes.root} noValidate autoComplete="off">
            <div>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="formatted-text-mask-input"
                  style={{ textAlign: 'left' }}
                >
                  Card Number
                </InputLabel>
                <Input
                  value={cardData.number}
                  onChange={handleChange}
                  fullWidth
                  name="number"
                  // type="text"
                  id="formatted-text-mask-input"
                  helperText="E.g.: 49..., 51..., 36..., 37..."
                  onFocus={(e) =>
                    setCardData({ ...cardData, focus: e.target.name })
                  }
                  inputComponent={TextMaskCustom}
                />
              </FormControl>
              <TextField
                value={cardData.name}
                type="text"
                name="name"
                label="Name"
                autoCapitalize="word"
                placeholder="Name Written in the card"
                //variant="filled"
                fullWidth
                onChange={(e) => {
                  setCardData({ ...cardData, name: e.target.value });
                }}
                onFocus={(e) =>
                  setCardData({ ...cardData, focus: e.target.name })
                }
              />
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="expiry-date-input"
                  style={{ textAlign: 'left' }}
                >
                  Valid Thru
                </InputLabel>
                <Input
                  value={cardData.expiry}
                  onChange={handleChange}
                  fullWidth
                  name="expiry"
                  id="expiry-date-input"
                  placeholder="MM/YY"
                  // type="number"
                  onFocus={(e) =>
                    setCardData({ ...cardData, focus: e.target.name })
                  }
                  inputComponent={TextMaskCustom}
                />
              </FormControl>
              <TextField
                value={cardData.cvc}
                type="tel"
                placeholder="Security Code"
                name="cvc"
                label="CVC"
                //variant="filled"
                fullWidth
                onChange={(e) => {
                  setCardData({ ...cardData, cvc: e.target.value });
                }}
                onFocus={(e) =>
                  setCardData({ ...cardData, focus: e.target.name })
                }
                onBlur={(e) => setCardData({ ...cardData, focus: 'number' })}
              />
            </div>
          </form>
        </div>
      )}
      <div className={classes.payButton}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            style={{ width: '60%' }}
            loading={loading}
            color="primary"
            variant="contained"
            mode="contained"
            disabled={loading}
            onClick={() => handlePayment()}
          >
            Confirm Payment
          </Button>
        )}
      </div>
    </div>
  );
};

const useStylesSumup = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: 5,
    },
    '& .MuiFormControl-root': {
      marginLeft: 5,
      marginRight: 5,
    },
    textAlign: 'center',
  },
  payButton: {
    textAlign: 'center',
    marginTop: 10,
  },
}));

const finishTransaction = async (amount, description) => {
  try {
    const res = await axios.get(`${API_BASE}/sumup/getToken`);
    const token = res.data.access_token;
    console.log(token);
    const body = {
      amount,
      description, // Henrique Aron, 2020-07-29
      token,
    };
    const response = await axios.post(`${API_BASE}/sumup/createCheckout`, body);
    console.log(response.data);
    return response;
  } catch (err) {
    //throw err;
    console.log(err);
  }
};

const sendIdsToUpdate = (cart) => {
  var ids = [];
  for (var i = 0; i < cart.length; ++i) ids.push(cart[i]._id);
  return ids;
};

const completeCheckout = async (id, card, next) => {
  const currentCart = returnCartContent();
  const body = {
    id,
    payment_type: 'card',
    card,
    ids: sendIdsToUpdate(currentCart),
  };
  try {
    const finishedPayment = await axios.put(
      `${API_BASE}/sumup/completePayment`,
      body
    );
    if (finishedPayment.data.status === 'PAID') {
      eraseCart();
    }
    console.log(finishedPayment.data);
    next();
    return finishedPayment;
  } catch (err) {
    console.log(err);
  }
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={
        props.name == 'number'
          ? [
              /[0-9]/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]
          : [/[0-9]/, /\d/, '/', /\d/, /\d/]
      }
      placeholderChar={'\u2000'}
      //showMask
    />
  );
}
