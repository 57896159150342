import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SignInComponent from './SignIn';
import SignUpComponent from './SignUp';
// styles
import useStyles from './styles';

function Login(props) {
  var [activeTabId, setActiveTabId] = useState(0);

  var [state, setState] = useState({
    isLoading: false,
    error: null,
    nameValue: '',
    loginValue: '',
    passwordValue: '',
    providerValue: '',
    phoneValue: '',
  });

  var classes = useStyles();

  function handleChange(param, e) {
    return setState({ ...state, [param]: e.target.value });
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img
          src={process.env.REACT_APP_LOGO_URL}
          alt="logo"
          className={classes.logotypeImage}
        />
        <Typography className={classes.logotypeText}>
          {process.env.REACT_APP_LOGIN_TEXT || 'User Centre'}
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="New User" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <SignInComponent {...props} editHandler={handleChange} />
          )}
          {activeTabId === 1 && (
            <SignUpComponent {...props} editHandler={handleChange} />
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © {new Date().getFullYear()} Scheduly. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default Login;
