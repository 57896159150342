import React, { useState } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from '@material-ui/core';

// styles
import useStyles from './styles';

// logo

// context
import { registerUser, useUserDispatch } from '../helper/UserContext';
//import { useUserDispatch, loginUser } from '../../context/UserContext';

const SignUpComponent = (props) => {
  var classes = useStyles();

  const userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [nameValue, setNameValue] = useState('');
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');
  var [providerValue, setProviderValue] = useState('');

  return (
    <React.Fragment>
      <Typography variant="h1" className={classes.greeting}>
        Welcome!
      </Typography>
      <Typography variant="h2" className={classes.subGreeting}>
        Create your account
      </Typography>
      <Fade in={error}>
        <Typography color="secondary" className={classes.errorMessage}>
          Something is wrong with your login or password :(
        </Typography>
      </Fade>
      <TextField
        id="email"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={loginValue}
        onChange={(e) => {
          setLoginValue(e.target.value);
          props.editHandler('loginValue', e);
        }}
        margin="normal"
        placeholder="Email Adress"
        type="email"
        fullWidth
      />
      <TextField
        id="password"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={passwordValue}
        onChange={(e) => {
          setPasswordValue(e.target.value);
          props.editHandler('passwordValue', e);
        }}
        margin="normal"
        placeholder="Password"
        type="password"
        fullWidth
      />
      <div className={classes.creatingButtonContainer}>
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            onClick={() =>
              registerUser(
                userDispatch,
                loginValue,
                passwordValue,
                props.history,
                setIsLoading,
                setError
              )
            }
            disabled={loginValue.length === 0 || passwordValue.length === 0}
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.createAccountButton}
          >
            Create your account
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default SignUpComponent;
