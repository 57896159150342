import React from 'react';
import {
  AppBar,
  Button,
  Grid,
  Icon,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from '../../assets/logo.png';
import CartDrawer from './CartDrawer';
import { signOut, useUserDispatch, useUserState } from './UserContext';
import { PortraitSharp } from '@material-ui/icons';
// import { Container } from './styles';

const useStyles = makeStyles({
  root: {
    width: '70%',
    margin: 'auto',
    marginTop: 20,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  appBar: {
    position: 'relative',
  },
  card: {
    maxWidth: 345,
    marginBottom: 10,
  },
  spacer: {
    marginBottom: 15,
  },
  headerButtons: {
    textTransform: 'none',
  },
});

function Header(props) {
  const classes = useStyles();

  const { isAuthenticated } = useUserState();
  const dispatch = useUserDispatch();

  const handleLogoClick = () => {
    props.history.push('/');
  };

  const handleCartClick = () => {
    if (!props.onCartClick) props.history.push('/cart');
    else props.onCartClick();
  };

  const handleSignIn = () => {
    props.history.push('/login');
  };

  const handleSignOut = () => {
    signOut(dispatch, props.history);
  };

  const theme = useTheme();

  return (
    <AppBar
      position="absolute"
      className={classes.appBar}
      id="app-header"
      style={
        process.env.REACT_APP_HEADER_COLOR
          ? { backgroundColor: `${process.env.REACT_APP_HEADER_COLOR}` }
          : {}
      }
    >
      <Toolbar>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ flex: 1 }}
              onClick={handleLogoClick}
              className={classes.headerButtons}
            >
              <div className={classes.logoContainer}>
                <img
                  src={`${process.env.REACT_APP_LOGO_URL}`}
                  alt="Logo"
                  id="logo-image"
                />
                {!!props.title && (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: 20,
                      color: process.env.REACT_APP_HEADER_ICONS_COLOR
                        ? process.env.REACT_APP_HEADER_ICONS_COLOR
                        : '#fff',
                    }}
                    color="inherit"
                  >
                    Book Now
                  </Button>
                )}
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} alignItems="center">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:
                  window.innerWidth < theme.breakpoints.values.sm
                    ? 'space-between'
                    : 'flex-end',
                height: '100%',
              }}
            >
              <Button
                onClick={() =>
                  isAuthenticated ? handleSignOut() : handleSignIn()
                }
                variant="outlined"
                style={{
                  color: process.env.REACT_APP_HEADER_ICONS_COLOR
                    ? process.env.REACT_APP_HEADER_ICONS_COLOR
                    : '#fff',
                }}
                color="inherit"
              >
                {isAuthenticated ? 'Sign Out' : 'Sign In'}
              </Button>
              {props.cart && <CartDrawer {...props} />}
              <Button
                onClick={() => props.history.push('/profile')}
                className={classes.headerButtons}
              >
                <Icon
                  style={{
                    color: process.env.REACT_APP_HEADER_ICONS_COLOR
                      ? process.env.REACT_APP_HEADER_ICONS_COLOR
                      : '#fff',
                  }}
                  fontSize="large"
                >
                  account_circle
                </Icon>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
