export const addItem = (item, next) => {
  var cart = [];

  if (typeof window !== 'undefined') {
    if (localStorage.getItem('cart'))
      cart = JSON.parse(localStorage.getItem('cart'));

    cart.push({ ...item, count: 1, date: Date.now() });

    // cart = Array.from(new Set(cart.map((p) => p._id))).map((id) => {
    //   return cart.find((p) => p._id === id);
    // });

    localStorage.setItem('cart', JSON.stringify(cart));
    next();
  }
};

export const returnCartTotal = () => {
  if (typeof window === 'undefined' || !localStorage.getItem('cart')) return 0;

  let cart = JSON.parse(localStorage.getItem('cart'));

  if (!cart) return 0;

  var total = 0;
  for (var i = 0; i < cart.length; ++i)
    total += cart[i].service.price * cart[i].count;

  return total;
};

export const returnCartContent = () => {
  var cartContent = [];
  if (typeof window === 'undefined' || !localStorage.getItem('cart')) return [];

  let cart = JSON.parse(localStorage.getItem('cart'));
  for (var i = 0; i < cart.length; ++i) cartContent.push(cart[i]);

  return cartContent;
};

export const checkStillValid = () => {
  var cartContent = [];
  if (typeof window === 'undefined' || !localStorage.getItem('cart')) return [];

  let cart = JSON.parse(localStorage.getItem('cart'));
  for (var i = 0; i < cart.length; ++i)
    if (cart[i].date + 20 * 60 * 1000 > Date.now()) cartContent.push(cart[i]);

  if (cartContent.length < 1) return localStorage.removeItem('cart');
  localStorage.setItem('cart', JSON.stringify(cartContent));
};

export const returnOldestDate = () => {
  if (typeof window === 'undefined' || !localStorage.getItem('cart')) return 0;

  let cart = JSON.parse(localStorage.getItem('cart'));
  var oldestDate = Date.now();
  var foundOlder = false;
  for (var i = 0; i < cart.length; ++i) {
    if (cart[i].date < oldestDate) {
      oldestDate = cart[i].date;
      foundOlder = true;
    }
  }
  if (foundOlder) return oldestDate;
  else return 0;
};

export const eraseCart = () => {
  if (typeof window === 'undefined' || !localStorage.getItem('cart'))
    return false;

  localStorage.removeItem('coupom');
  localStorage.removeItem('cart');
  return true;
};

export const getCostumerName = async () => {
  if (!localStorage.getItem('cart')) return false;
  let cart = JSON.parse(localStorage.getItem('cart'));
  if (cart.length > 0) return cart[0].name;
  else return false;
};

export const deleteItemFromCart = async (itemId) => {
  if (typeof window === 'undefined' || !localStorage.getItem('cart'))
    return false;

  let cart = JSON.parse(localStorage.getItem('cart'));
  cart = cart.filter((item) => item._id != itemId);
  if (cart.length === 0) localStorage.removeItem('cart');
  else localStorage.setItem('cart', JSON.stringify(cart));
};

export function filterCart({ amount, service, setCoupomAdded, discountCode }) {
  if (typeof window === 'undefined') return false;

  const cart = returnCartContent();
  if (!service || !amount) return;
  for (var i = 0; i < cart.length; ++i) {
    if (cart[i].service._id == service._id) {
      cart[i].service.price = cart[i].service.price * (1 - amount);
      localStorage.setItem('coupom', discountCode);
      setCoupomAdded(true);
      localStorage.setItem('cart', JSON.stringify(cart));
      return true;
    }
  }
  return false;
}

export async function filterCartFreeService({
  amount,
  service,
  freeService,
  setCoupomAdded,
  discountCode,
}) {
  const cart = returnCartContent();
  if (!service || !amount) return;
  var sawService = false,
    freeServiceIndex;
  for (var i = 0; i < cart.length; ++i) {
    if (cart[i].service._id == service._id) sawService = true;
    if (cart[i].service._id == freeService._id) freeServiceIndex = i;
  }
  if (sawService && freeServiceIndex != null) {
    cart[freeServiceIndex].service.price =
      cart[freeServiceIndex].service.price * (1 - amount);
    localStorage.setItem('coupom', discountCode);
    setCoupomAdded(true);
  }
  localStorage.setItem('cart', JSON.stringify(cart));
  return;
}
