import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import {
  returnCartContent,
  returnCartTotal,
  checkStillValid,
  deleteItemFromCart,
  filterCart,
  filterCartFreeService,
} from './helper/Cart';
import { slotTimeToString } from './buildTimeStringsArray';
import moment from 'moment';
import axios from 'axios';
import DropIn from 'braintree-web-drop-in-react';
import { Button, TextField } from '@material-ui/core';
import { Divider } from 'material-ui';
import { API_BASE } from './helper/constants';
import { Close } from '@material-ui/icons';
import { useUserState } from './helper/UserContext';

const addresses = [
  '1 Material-UI Drive',
  'Reactville',
  'Anytown',
  '99999',
  'USA',
];
const payments = [
  { name: 'Card type', detail: 'Visa' },
  { name: 'Card holder', detail: 'Mr John Smith' },
  { name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234' },
  { name: 'Expiry date', detail: '04/2024' },
];

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  coupomField: {
    marginRight: 5,
  },
}));

export default function CartView() {
  const classes = useStyles();

  const [products, setProducts] = useState([]);
  const [paymentToken, setPaymentToken] = useState(null);
  const [instance, setInstance] = useState({});
  const [discountCode, setDiscountCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [coupomError, setCoupomError] = useState(false);
  const [coupomAdded, setCoupomAdded] = useState(false);

  const { user } = useUserState();

  useEffect(() => {
    fetchProducts();
    // getToken();
  }, []);

  // const getToken = async () => {
  //   try {
  //     const response = await axios.get(`${API_BASE}/braintree/getToken`);
  //     console.log(response.data);
  //     setPaymentToken(response.data.clientToken);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const fetchProducts = () => {
    checkStillValid();
    setProducts(returnCartContent());
  };

  const handleDelete = async (product) => {
    await deleteItemFromCart(product._id);
    fetchProducts();
  };

  const handleCoupomAdd = async () => {
    const cart = JSON.parse(localStorage.getItem('cart'));
    const newCart = await axios.post(`${API_BASE}/sales/updateCart`, {
      cart,
      coupomCode: discountCode,
    });

    localStorage.setItem('coupom', discountCode);

    // Updates the cart with the new one returned from the server
    localStorage.setItem('cart', JSON.stringify(newCart.data.cart));

    // Updates the UI with new price
    fetchProducts();
  };

  // const handleCoupomAdd = async () => {
  //   //if (coupomAdded || localStorage.getItem('coupomAdded') == 'true') return;
  //   if (localStorage.getItem('coupom') == discountCode) return;
  //   try {
  //     setLoading(true);
  //     const res = await axios.post(`${API_BASE}/sales/applyCoupom`, {
  //       code: discountCode,
  //     });
  //     const { amount, service, isFreeService, freeService } = res.data;
  //     if (!amount || !service) {
  //       setLoading(false);
  //       setCoupomError(true);
  //       return;
  //     }
  //     //Handle product prices update
  //     if (isFreeService) {
  //       await filterCartFreeService({
  //         amount,
  //         service,
  //         freeService,
  //         setCoupomAdded,
  //         discountCode,
  //       });
  //       fetchProducts();
  //     } else {
  //       if (
  //         !(await filterCart({ amount, service, setCoupomAdded, discountCode }))
  //       )
  //         setCoupomError(true);
  //       fetchProducts();
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //     setCoupomError(true);
  //   }
  // };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <Divider />
      <List disablePadding>
        {products.map((product) => (
          <ListItem className={classes.listItem} key={product.service.name}>
            <ListItemText
              primary={product.service.name}
              secondary={
                <React.Fragment>
                  {moment(product.slot_id.slot_date).format('YYYY/MM/DD') +
                    ' at ' +
                    slotTimeToString(product.slots[0])}
                  <Typography>
                    {product.service.timeInterval * 15 + ' min'}
                  </Typography>
                  <Typography>
                    {'By: ' + product.provider.name} {'\n'}
                  </Typography>
                </React.Fragment>
              }
            />
            <Typography variant="body2">£{product.service.price}</Typography>
            <Button
              style={{ height: 40, width: 40, marginLeft: 20 }}
              onClick={() => handleDelete(product)}
            >
              <Close />
            </Button>
          </ListItem>
        ))}
        <Divider />
        {CartTotal()}
      </List>
      <Divider />
      <div className={classes.row}>
        <TextField
          placeholder="DISCOUNT1234"
          label="Discount code"
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
          error={coupomError}
          helperText={
            coupomError ? 'Your coupom is not valid for this purchase' : ''
          }
          className={classes.coupomField}
        />
        <Button variant="contained" color="primary" onClick={handleCoupomAdd}>
          Add
        </Button>
      </div>
      {/* <Grid container spacing={2}>
        {UserInfoDisplay()}
        {PaymentInfoDisplay()}
      </Grid> */}
    </React.Fragment>
  );
}

const CartTotal = () => {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemText primary="Total" />
      <Typography variant="subtitle1" className={classes.total}>
        £{returnCartTotal()}
      </Typography>
    </ListItem>
  );
};

const UserInfoDisplay = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Shipping
      </Typography>
      <Typography gutterBottom>John Smith</Typography>
      <Typography gutterBottom>{addresses.join(', ')}</Typography>
    </Grid>
  );
};

const PaymentInfoDisplay = () => {
  const classes = useStyles();
  return (
    <Grid item container direction="column" xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Payment details
      </Typography>
      <Grid container>
        {payments.map((payment) => (
          <React.Fragment key={payment.name}>
            <Grid item xs={6}>
              <Typography gutterBottom>{payment.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{payment.detail}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};
