import React from 'react';
import axios from 'axios';
import { API_BASE } from './constants';

export var UserStateContext = React.createContext();
export var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true, user: action.payload };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false, user: {} };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: false, //!!localStorage.getItem('id_token'),
    user: {},
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  registerUser,
};

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
  redirectTo
) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    try {
      setError(null);
      console.log(password);
      const signIn = await axios.post(`${API_BASE}/auth/login`, {
        email: login,
        password,
      });
      localStorage.setItem('id_token', signIn.data.token);
      setIsLoading(false);
      dispatch({ type: 'LOGIN_SUCCESS', payload: signIn.data.user });
      console.log(history);
      console.log(redirectTo);
      //if (history.location.pathname) history.push(history.location.pathname);
      if (redirectTo) return history.push({ pathname: redirectTo });

      history.push('/profile');
    } catch (err) {
      setIsLoading(false);
      setError(true);
      console.log(err);
    }
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
    setIsLoading(false);
  }
}

async function registerUser(
  dispatch,
  email,
  password,
  history,
  setIsLoading,
  setError
) {
  if (!!email && !!password) {
    try {
      setIsLoading(true);
      const signUp = await axios.post(`${API_BASE}/auth/register`, {
        email,
        password,
      });
      console.log(signUp);
      const { token } = signUp.data;
      localStorage.setItem('id_token', token);
      console.log(password);
      loginUser(dispatch, email, password, history, setIsLoading, setError);
    } catch (err) {
      setError(true);
      setIsLoading(false);
      console.log(err);
    }
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('id_token');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}
