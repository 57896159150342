import moment from 'moment';

export function buildTimeStringsArray() {
  var slots = [0, 96];
  var stringArray = [];
  for (var i = 0; i < slots[1]; ++i) {
    const hour = Math.floor((i * 15) / 60);
    const minutes = i * 15 - Math.floor((i * 15) / 60) * 60;
    const timeString =
      `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
    stringArray.push(timeString);
  }
  return stringArray;
}

export function timeArrayByStartFinish(start, finish, lowerLimit, upperLimit) {
  var stringArray = [];
  for (var i = start; i <= finish; ++i) {
    const hour = Math.floor((i * 15) / 60);
    const minutes = i * 15 - Math.floor((i * 15) / 60) * 60;
    const timeString =
      `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
    if (lowerLimit >= 0 && upperLimit) {
      if (i >= lowerLimit && i <= upperLimit) stringArray.push(timeString);
    } else stringArray.push(timeString);
  }
  return stringArray;
}

export function returnInicialLetters(name) {
  var letters = '';
  const [firstName, lastName] = name.split(' ', 2);
  if (!lastName && firstName.length > 1)
    return firstName.substring(0, 2).toUpperCase();
  letters += firstName.substring(0, 1) + lastName.substring(0, 1);
  return letters;
}

export function timeStringToSlotNumber(string, step = 0) {
  const [h, m] = string.split(':');
  const slot = h * 4 + m / 15 + step;
  return slot;
}

export function slotTimeToString(slot) {
  const hour = Math.floor((slot * 15) / 60);
  const minutes = slot * 15 - Math.floor((slot * 15) / 60) * 60;
  const timeString =
    `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
  return timeString;
}

// export function range(start, end) {
//   return Array(end - start + 1)
//     .fill()
//     .map((_, idx) => start + idx);
// }

export function returnScheduleDisabled(
  time1,
  time2,
  slots,
  providers,
  providerSelected,
  date
) {
  var scheduleDisabled = false;
  for (var i = 0; i < slots.length; ++i) {
    if (!moment(slots[i].slot_date).isSame(date)) continue;
    if (
      slots[i].provider == null ||
      slots[i].provider._id != providers[providerSelected]._id
    )
      continue;
    const lb = timeStringToSlotNumber(time1.format('HH:mm'));
    const ub = timeStringToSlotNumber(time2.format('HH:mm'));
    if (
      (lb > slots[i].slot_time[0] && lb < slots[i].slot_time[1]) ||
      (ub > slots[i].slot_time[0] && ub < slots[i].slot_time[1]) ||
      (lb == slots[i].slot_time[0] && ub == slots[i].slot_time[1])
    ) {
      scheduleDisabled = true;
      break;
    }
  }
  return scheduleDisabled;
}

export function returnScheduleDisabledV2(
  time1,
  time2,
  slots,
  providers,
  providerSelected
) {
  let t1 = timeStringToSlotNumber(time1.format('HH:mm'));
  let t2 = timeStringToSlotNumber(time2.format('HH:mm'));

  let timeSet = new Set();

  for (let i = 0; i < slots.length; ++i) {
    if (slots[i].provider == providers[providerSelected]._id)
      [...Array(parseInt(slots[i].slot_time[1] - slots[i].slot_time[0])).keys()]
        .map((item) => item + slots[i].slot_time[0])
        .forEach((item) => timeSet.add(item));
  }

  const uniqueSlots = Array.from(timeSet);

  const timesBetween = [...Array(t2 - t1).keys()].map((item) => item + t1);

  if (timesBetween.some((item) => uniqueSlots.includes(item))) {
    return true;
  }

  return false;
}
