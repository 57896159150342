import React, { Component } from 'react';
import logo from './logo.svg';
import AppointmentApp from './components/AppointmentsApp';
import ServiceLister from './components/ServiceLister';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import './App.css';
import CartView from './components/CartView';
import CartWrapper from './components/CartWrapper';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { returnOldestDate, checkStillValid } from './components/helper/Cart';
import Home from './components/Home/Home';
import Login from './components/auth/Login';
import PublicRoute from './components/helper/PublicRoute';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import PrivateRoute from './components/helper/PrivateRoute';
import Profile from './components/Profile/Profile';
import { loginUser } from './components/helper/UserContext';
import axios from 'axios';
import { API_BASE } from './components/helper/constants';
import { UserDispatchContext } from './components/helper/UserContext';
import LoadingView from './components/helper/LoadingView';
import ConsultationForm from './pages/ConsultationForm';

class App extends Component {
  state = {
    time: 20 * 60,
    timeStr: '',
    service: '',
    loading: true,
  };
  componentDidMount() {
    this.startTimer();
    this.authenticate();
    if (process.env.SHORT_COMPANY_NAME)
      document.title = process.env.SHORT_COMPANY_NAME + 'Scheduler';
  }
  authenticate = async () => {
    this.setState({ loading: true });
    if (!localStorage.getItem('id_token')) {
      this.setState({ loading: false });
      return;
    }
    const token = localStorage.getItem('id_token');
    try {
      const signIn = await axios.post(`${API_BASE}/auth/signinbytoken`, {
        token,
      });
      if (signIn.data.user)
        this.context({
          type: 'LOGIN_SUCCESS',
          payload: signIn.data.user,
        });

      this.setState({ loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };
  startTimer = () => {
    setInterval(() => {
      checkStillValid();
      const oldestTime = returnOldestDate();
      if (!oldestTime == 0) {
        const hour = `${Math.floor(
          (oldestTime + 20 * 60 * 1000 - Date.now()) / 60 / 1000
        )}`.padStart(2, 0);
        const minutes = `${Math.floor(
          ((oldestTime + 20 * 60 * 1000 - Date.now()) / 1000) % 60
        )}`.padStart(2, 0);
        const timeString = hour + ':' + minutes;
        this.setState((prevState) => {
          return {
            timeStr: timeString,
          };
        });
      }
    }, 1000);

    // useEffect(() => {
    //   setTimeout(() => {
    //     setTimeLeft(timeLeft - 1);
    //   }, 1000);
    // });
  };
  resetTimeString = () => {
    this.setState({ timeStr: '' });
  };
  selectService = (serviceId) => {
    this.setState({ service: serviceId });
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <LoadingView />
        ) : (
          <MuiThemeProvider>
            <BrowserRouter>
              <Switch>
                <Route
                  path="/services"
                  exact
                  render={(props) => (
                    <ServiceLister
                      {...props}
                      setService={this.selectService}
                      timeRemaining={
                        this.state.time > 0 ? this.state.timeStr : ''
                      }
                    />
                  )}
                />
                <Route
                  path="/schedule"
                  exact
                  render={(props) => (
                    <AppointmentApp
                      {...props}
                      service={this.state.service}
                      timeRemaining={
                        this.state.time > 0 ? this.state.timeStr : ''
                      }
                      startCount={this.startTimer}
                    />
                  )}
                />
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Home
                      {...props}
                      service={this.state.service}
                      timeRemaining={
                        this.state.time > 0 ? this.state.timeStr : ''
                      }
                      startCount={this.startTimer}
                    />
                  )}
                />
                <Route
                  path="/cart"
                  exact
                  render={(props) => (
                    <CartWrapper
                      {...props}
                      timeRemaining={
                        this.state.time > 0 ? this.state.timeStr : ''
                      }
                      resetTimer={() => this.resetTimeString()}
                    />
                  )}
                />
                <PublicRoute path="/login" exact component={Login} />
                <PublicRoute
                  path="/forgot_password"
                  exact
                  component={ForgotPassword}
                />
                <PublicRoute
                  path="/reset_password/:token"
                  exact
                  component={ResetPassword}
                />
                <Route
                  path="/consultation_form/:formId"
                  exact
                  component={ConsultationForm}
                />
                <PrivateRoute path="/profile" exact component={Profile} />
              </Switch>
            </BrowserRouter>
          </MuiThemeProvider>
        )}
      </div>
    );
  }
}
App.contextType = UserDispatchContext;

export default App;
