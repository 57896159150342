const labels = {
  LONG_USER_INTERACTIVE_FORM: {
    TITLE: {
      en: 'Laser & Lipstick IPL Consultation and Consent Form',
      pt: 'Formuário de consulta e consetimento para tratamento de Laser & ??? IPL',
    },
    PERSON_TITLE: {
      en: 'Laser & Lipstick IPL Consultation and Consent Form',
      pt: 'Formuário de consulta e consetimento para tratamento de Laser & ??? (Luz de pulso intensa)',
    },
    FIRST_NAME: { en: 'First Name', pt: 'Primeiro Nome' },
    SURNAME: { en: 'Surname', pt: 'Sobrenome' },
    ADDRESS: { en: 'Address', pt: 'Endereço' },
    OCCUPATION: { en: 'Occupation', pt: 'Ocupação' },
    TYPE_OF_TREATMENT: {
      en: 'Type of treatment',
      pt: 'Tipo de tratamento',
      options: [
        // As opções do seletor também devem ser traduzidas
        { en: 'Hair Removal', pt: 'Remoção de Pelos' },
        { en: 'Skin Tone Correction', pt: 'Correção de tom de pele' },
        // ...
      ],
    },
    HAIR_REMOVAL_METHOD: {
      // Os dois próximos são dependentes desse, em caso negativo desse, eles não mostram
      en: '',
      pt: '',
    },
  },
};

export default labels;
