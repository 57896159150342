import {
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import axios from 'axios';
import { API_BASE } from '../helper/constants';
import { useUserState } from '../helper/UserContext';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import Header from '../helper/Header';

// import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 20,
    margin: 'auto',
    marginLeft: '5%',
    marginRight: '5%',
    minWidth: 300,
    //backgroundColor: '#fff',
    //borderRadius: 10,
  },
  main: {
    padding: 20,
  },
  root: {
    width: '100%',
    //maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  item: {
    marginBottom: '5px',
  },
}));

function Profile(props) {
  const [appointments, setAppointments] = useState({ future: [], past: [] });
  const { user } = useUserState();
  const classes = useStyles();

  useEffect(() => {
    getAppointments();
  }, []);

  async function getAppointments() {
    if (!user) return;
    try {
      const appointmentsF = await axios.get(
        `${API_BASE}/appointment/list/byEmail/${user.email}/future`
      );
      const appointmentsP = await axios.get(
        `${API_BASE}/appointment/list/byEmail/${user.email}/past`
      );
      setAppointments({
        ...appointments,
        future: appointmentsF.data,
        past: appointmentsP.data,
      });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <Header {...props} cart title="Book Now" />
      <div className={classes.wrapper}>
        <Typography
          style={{ marginLeft: 20, paddingTop: 20, paddingBottom: 20 }}
          variant="h1"
        >
          Profile Manager
        </Typography>
        <Paper>
          <div className={classes.main}>
            <Typography variant="h3">Your appointments</Typography>
            <AppointmentsList {...props} appointments={appointments} />
          </div>
        </Paper>
      </div>
    </>
  );
}

function AppointmentsList({ appointments, ...props }) {
  const [timestamp, setTimestamp] = useState('');
  const classes = useStyles();

  useEffect(() => {
    fetchTimestamp();
  }, []);

  const fetchTimestamp = async () => {
    try {
      const time = await axios.get(
        'http://worldtimeapi.org/api/timezone/Europe/London'
      );
      const timestamp = time.data.unixtime;
      setTimestamp(timestamp);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <List className={classes.root}>
        <ListSubheader style={{ fontSize: 24 }}>
          Future Appointments
        </ListSubheader>
        {appointments.future.length ? (
          appointments.future.map((item) => (
            <ListItem className={classes.item}>
              <AppointmentItem
                {...props}
                appointment={item}
                timestamp={timestamp}
              />
            </ListItem>
          ))
        ) : (
          <Typography>No future appointments</Typography>
        )}
        <ListSubheader style={{ fontSize: 24 }}>
          Past Appointments
        </ListSubheader>
        {appointments.future.length ? (
          appointments.past.map((item) => (
            <ListItem className={classes.item}>
              <AppointmentItem
                {...props}
                appointment={item}
                timestamp={timestamp}
              />
            </ListItem>
          ))
        ) : (
          <Typography>No past appointments</Typography>
        )}
      </List>
    </div>
  );
}

function AppointmentItem({ appointment, timestamp, ...props }) {
  function handleReeschedule() {
    console.log('I want to reeschedule');
    props.history.push({
      pathname: '/schedule',
      appointment: appointment,
      service: appointment.service._id,
      isReeschedule: true,
    });
  }

  const isReeschedulable = () => {
    const appointmentTime =
      new Date(appointment.slot_id.slot_date).getTime() / 1000 +
      appointment.slots[0] * 15 * 60;
    return (
      timestamp <= appointmentTime - 86400 * 2 && !appointment.isReescheduled
    );
  };

  const spanStyle = { color: '#00C853' };
  const {
    name,
    phone,
    email,
    service,
    slot_id,
    slots: appointmentSlot,
    provider,
  } = appointment;
  const { slot_date: appointmentDate } = slot_id;
  return (
    <Card className="book-item-card" elevation={3}>
      <ListItemText
        primary={
          <>
            <p>
              Name: <span style={spanStyle}>{name}</span>
            </p>
            <p>
              Number: <span style={spanStyle}>{phone}</span>
            </p>
            <p>
              Email: <span style={spanStyle}>{email}</span>
            </p>
            <p>
              Appointment:{' '}
              <span style={spanStyle}>
                {moment(appointmentDate).format('dddd[,] MMMM Do[,] YYYY')}
              </span>{' '}
              at{' '}
              <span style={spanStyle}>
                {moment()
                  .hour(0)
                  .minute(0)
                  .add(appointmentSlot[0] * 15, 'minutes')
                  .format('HH:mm')}
              </span>
            </p>
            <p>
              Service: <span style={spanStyle}>{service && service.name}</span>
            </p>
            <p>
              By: <span style={spanStyle}>{provider && provider.name}</span>
            </p>
          </>
        }
      />
      {isReeschedulable() && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleReeschedule}
          style={{ marginBottom: 10 }}
        >
          Reeschedule Appointment
        </Button>
      )}
    </Card>
  );
}

export default Profile;
