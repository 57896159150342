import { TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { API_BASE } from './helper/constants';
import Header from './helper/Header';
import LoadingView from './helper/LoadingView';
import { useUserState } from './helper/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    margin: 'auto',
    marginTop: 20,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  appBar: {
    position: 'relative',
  },
  card: {
    maxWidth: 345,
    marginBottom: 10,
  },
  spacer: {
    marginBottom: 15,
  },
  heading: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ServiceLister(props) {
  const classes = useStyles();

  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [query, setQuery] = useState('');

  const servicesToShow = useMemo(() => {
    console.log(query);
    return services.filter((item) => {
      if (query) {
        const name = item.name.toLowerCase();
        return name.indexOf(query.toLowerCase()) > -1;
      } else return true;
    });
  }, [query, services]);

  const categoriesToShow = useMemo(() => {
    return categories.filter((item) => {
      if (query) {
        for (let i = 0; i < servicesToShow.length; i++) {
          if (servicesToShow[i].category._id == item._id) return true;
        }
        return false;
      } else return true;
    });
  }, [services, query, categories]);

  const { user } = useUserState();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const services = await axios.get(API_BASE + `/service/list`);
      const categories = await axios.get(API_BASE + '/category/list');
      setCategories(categories.data);
      setServices(services.data);
      setLoaded(true);
    } catch (err) {
      setLoaded(true);
    }
    console.log(services.data, categories.data);
  };

  const returnDurationString = (slotCount) => {
    const time = slotCount * 15;
    return `${time}`;
  };

  const handleServiceSelect = (itemId) => {
    console.log(itemId);

    if (!Object.keys(user).length)
      return props.history.push({
        pathname: '/login',
        redirectTo: '/services',
      });

    props.history.push({ pathname: '/schedule', service: itemId });
  };

  const renderServicesRow = (item) => {
    return (
      <div>
        <Card className={classes.card}>
          <CardActionArea onClick={() => handleServiceSelect(item._id)}>
            <CardContent>
              <Typography variant="h6" component="h4">
                {item.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {returnDurationString(item.timeInterval)} min
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                £{(item.price || 0).toFixed(2)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  };

  const renderRow = (title, content) => {
    // var displayArr = [];
    // for (var j = 0; j < servicesToShow.length; ++j) {
    //   if (servicesToShow[j].category.name == title) {
    //     //console.log(servicesToShow[j].category.name);
    //     displayArr.push(servicesToShow[j]);
    //   }
    // }
    return (
      <div className={classes.spacer}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} variant="h5">
              {title}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              {content.map((item, i) => {
                return renderServicesRow(item);
              })}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };

  return (
    <div>
      <Header title="Service Navigator" cart {...props} />

      <div className={classes.root}>
        <span>{props.timeRemaining}</span>
        <Card
          style={{
            backgroundColor: '#eee',
            borderRadius: '10px',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              marginLeft: '10px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            variant="h5"
          >
            What do you need?{' '}
          </Typography>
          <TextField
            style={{ display: 'block' }}
            name="serviceQuery"
            value={query}
            color="secondary"
            floatingLabelText="What do you need?"
            onChange={(e) => setQuery(e.target.value)}
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              display: 'flex',
              flex: 1,
            }}
          />
        </Card>
        {!loaded ? (
          <LoadingView />
        ) : (
          categoriesToShow.map((item, i) =>
            renderRow(
              item.name,
              servicesToShow.filter(
                (_item) => _item.category.name === item.name
              )
            )
          )
        )}
      </div>
    </div>
  );
}
