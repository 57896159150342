import React from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

export const CustomTextField = ({
  name,
  label,
  register,
  registerOptions = {},
  size = {},
}) => {
  return (
    <Grid item xs={size.xs || 12} sm={size.sm || 6}>
      <InputLabel>{label}</InputLabel>
      <TextField
        fullWidth
        name={name}
        id={name}
        {...register(name, registerOptions)}
      />
    </Grid>
  );
};

export const CustomSelectField = ({
  name,
  label,
  options,
  register,
  registerOptions = {},
  size = {},
}) => {
  return (
    <Grid item xs={size.xs || 12} sm={size.sm || 6}>
      <InputLabel>{label}</InputLabel>

      <Select {...register(name, registerOptions)} fullWidth>
        <MenuItem value={''}> </MenuItem>
        {options.map((item) => (
          <MenuItem value={item.value}>{item.name}</MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
