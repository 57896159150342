import { Button, Icon, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assets/logo.png';
import CartDrawer from '../helper/CartDrawer';
import { API_BASE } from '../helper/constants';
import Header from '../helper/Header';
import { useUserState } from '../helper/UserContext';

const HandleBookContext = React.createContext();

export default function Home(props) {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useUserState();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const services = await axios.get(`${API_BASE}/home`);
      setServices(services.data);
      setLoading(false);
      console.log(services.data);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleBook = (service) => {
    console.log(service);
    if (!user.email)
      return props.history.push({
        pathname: '/login',
        redirectTo: service ? '/' : '/services',
      });

    if (!service) return props.history.push({ pathname: '/services' });

    props.history.push({ pathname: '/schedule', service: service._id });
  };

  const handleAllCategories = () => {
    props.history.push({ pathname: '/services' });
  };

  return (
    <div className="home-wrapper">
      {/* <div className="home-background" /> */}
      {/* <div className="fader" /> */}

      <Header cart {...props} />

      <div className="container-home-screen">
        <div className="home-title">
          <Typography
            style={{
              fontWeight: '900',
              fontSize: 'calc(100% + 1vw)',
              textAlign: 'center',
              color: '#333',
              zIndex: 100,
              marginBottom: '1rem',
            }}
          >
            {process.env.REACT_APP_HOME_TITLE}
          </Typography>
          <Button
            variant="contained"
            style={{
              display: 'block',
              backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
              width: '80%',
              maxWidth: 1000,
              alignSelf: 'center',
              color: 'white',
            }}
            onClick={() => handleAllCategories()}
          >
            See all categories
          </Button>
        </div>
        <div className="home-container">
          <HandleBookContext.Provider value={handleBook}>
            <div className="home-grid">
              {services.map((item, index) => (
                <HomeItem item={item} key={item._id} />
              ))}
            </div>
          </HandleBookContext.Provider>
        </div>
      </div>
    </div>
  );
}

function HomeItem({ item }) {
  const { service, discount, categories } = item;
  useEffect(() => {
    //getImage();
  }, []);
  const getImage = async () => {
    try {
      const image = await axios.get(`${API_BASE}/home/image/${item._id}`);
      console.log(image);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="home-item">
      <img
        className="home-item-image"
        src={`${API_BASE}/home/image/${item._id}`}
      />
      <Footer categories={categories} service={service} discount={discount} />
    </div>
  );
}

function Footer({ service, discount, categories }) {
  const handleBook = useContext(HandleBookContext);

  const returnName = () => {
    return (
      (service && service.name) ||
      categories.map((_item, index) =>
        categories.length - 1 == index ? _item.name : `${_item.name}, `
      )
    );
  };

  return (
    <div className="home-item-footer-container">
      <div className="home-item-footer-bottom-container">
        <div>
          {service && (
            <Typography
              style={{
                padding: 3,
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: 'black',
                borderRadius: 8,
                fontSize: 17,
                color: '#f9f9f9',
                fontWeight: '900',
                marginTop: '10px',
                textDecoration: discount != 0 ? 'line-through' : 'none',
              }}
            >
              £ {service && (service.price || 0).toFixed(2)}
            </Typography>
          )}
          {discount != 0 && (
            <Typography
              style={{
                padding: 3,
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: '#f429e0',
                borderRadius: 8,
                fontSize: 17,
                color: '#f9f9f9',
                fontWeight: '900',
                marginTop: '10px',
              }}
            >
              {service
                ? `£ ${(service.price * (1 - discount)).toFixed(2)}`
                : `${discount * 100}% OFF`}
            </Typography>
          )}
        </div>
        <Button
          variant="contained"
          style={{
            display: 'block',
            backgroundColor: '#F429E0',
            marginTop: '10px',
          }}
          onClick={() => handleBook(service)}
        >
          <Typography style={{ color: 'white', fontWeight: '800' }}>
            {process.env.REACT_APP_BOOK_NOW_BUTTON_TEXT
              ? `${process.env.REACT_APP_BOOK_NOW_BUTTON_TEXT}`
              : 'BOOK NOW'}
          </Typography>
        </Button>
      </div>
      {service && (
        <Typography
          style={{ fontWeight: '600' }}
          className="home-item-footer-category-label"
        >
          {service.category.name}
        </Typography>
      )}
    </div>
  );
}

const useStyles = makeStyles({});
