import React, { useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

// import { Container } from './styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function LoadingView() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress size={50} />
    </div>
  );
}

export default LoadingView;
