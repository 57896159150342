import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography';
import { returnCartContent, returnCartTotal, checkStillValid } from './Cart';
import { slotTimeToString } from '../buildTimeStringsArray';
import moment from 'moment';
import { Paper, Icon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  listItem: {
    padding: theme.spacing(1, 2),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  headerButtons: {
    textTransform: 'none',
  },
  cartButton: {
    display: 'flex',
    margin: 'auto',
    width: '90%',
  },
}));

export default function CartDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    drawerOpen: false,
    products: returnCartContent(),
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, drawerOpen: open });
  };

  const list = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: false,
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List disablePadding>
        {state.products.map((product) => {
          return <CartItem product={product} />;
        })}
        {state.products.length === 0 && (
          <Typography align="center">Your cart is empty</Typography>
        )}
        {CartTotal()}
      </List>
      <Button
        className={classes.cartButton}
        variant="outlined"
        onClick={() => props.history.push('/cart')}
      >
        Go to cart
      </Button>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <Button onClick={toggleDrawer(true)} className={classes.headerButtons}>
          <Icon
            style={{
              color: process.env.REACT_APP_HEADER_ICONS_COLOR
                ? process.env.REACT_APP_HEADER_ICONS_COLOR
                : '#fff',
            }}
            fontSize="large"
          >
            shopping_cart
          </Icon>
        </Button>
        <SwipeableDrawer
          anchor={'right'}
          open={state.drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

const CartTotal = () => {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemText primary="Total" />
      <Typography variant="subtitle1" className={classes.total}>
        £{returnCartTotal()}
      </Typography>
    </ListItem>
  );
};

const CartItem = ({ product }) => {
  const classes = useStyles();
  return (
    <Paper>
      <ListItem button className={classes.listItem} key={product.service.name}>
        <ListItemText
          primary={product.service.name}
          secondary={
            <React.Fragment>
              {moment(product.slot_date).format('YYYY/MM/DD') +
                ' at ' +
                slotTimeToString(product.slots[0])}
              <Typography>
                {product.service.timeInterval * 15 + ' min'}
              </Typography>
              <Typography>
                {'By: ' + product.provider.name} {'\n'}
              </Typography>
            </React.Fragment>
          }
        />
        <Typography variant="body2">£{product.service.price}</Typography>

        <Divider />
      </ListItem>
    </Paper>
  );
};
