import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';

// logo

// context
import { useUserDispatch, loginUser } from '../helper/UserContext';

const SignInComponent = (props) => {
  var classes = useStyles();

  const userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState('');
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');
  const [prevRoute, setPrevRoute] = useState('');

  useEffect(() => {
    console.log(props.location.redirectTo);
    setPrevRoute(props.location);
  }, [props.location]);

  const handleBack = () => {
    console.log(props.history.location.pathname, props.history);
    return props.history.push('/');
  };

  return (
    <React.Fragment>
      <Typography variant="h3" className={classes.greeting}>
        Good Morning! Let's sign in, shall we?
      </Typography>
      {!!error && (
        <Fade in={error}>
          <Typography color="secondary" className={classes.errorMessage}>
            Something is wrong with your login or password :(
          </Typography>
        </Fade>
      )}
      <form>
        <TextField
          id="email"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
          }}
          value={loginValue}
          onChange={(e) => {
            setLoginValue(e.target.value);
            props.editHandler('loginValue', e);
          }}
          margin="normal"
          placeholder="Email Address"
          type="email"
          fullWidth
        />
        <TextField
          id="password"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
          }}
          value={passwordValue}
          onChange={(e) => {
            setPasswordValue(e.target.value);
            props.editHandler('passwordValue', e);
          }}
          margin="normal"
          placeholder="Password"
          type="password"
          fullWidth
        />
        <div className={classes.formButtons}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
            <Button
              disabled={loginValue.length === 0 || passwordValue.length === 0}
              onClick={() =>
                loginUser(
                  userDispatch,
                  loginValue,
                  passwordValue,
                  props.history,
                  setIsLoading,
                  setError,
                  props.location.redirectTo
                )
              }
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Login
            </Button>
          )}
          <Button
            color="primary"
            size="large"
            onClick={() => props.history.push('/forgot_password')}
            className={classes.forgetButton}
          >
            Forgot Password
          </Button>
        </div>
        <div className={classes.backButton}>
          <Button
            onClick={handleBack}
            color="primary"
            variant="contained"
            size="small"
            fullWidth
          >
            Back
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SignInComponent;
