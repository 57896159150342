import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Themes from './themes';
import * as serviceWorker from './serviceWorker';
import { UserProvider } from './components/helper/UserContext';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(
  <HttpsRedirect>
    <UserProvider>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </UserProvider>
  </HttpsRedirect>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
